<template>
  <div id="blog" style="margin-top: 50px;">
    <div class="blog-header-container" id="blog-header-container">
      <h1>{{$t("BLOG_HEADER")}}</h1>
      <span>{{$t("BLOG_CONTENT")}}</span>
    </div>
    <blog-carousel />
  </div>
</template>

<script>
import MobileBlogCarousel from "@/components/mobile/BlogCarousel";
export default {
  name: "Blog",
  components: {
    "blog-carousel": MobileBlogCarousel
  }
}
</script>

<style scoped>
#blog {
  width: 100%;
  height: calc(var(--vh) * 100);
  padding: 20px 0;
}
.blog-header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
}

.blog-header-container h1 {
  font-weight: 700;
  font-size: calc(var(--oh) * 3.5);
  line-height: calc(var(--oh) * 4.3);
  color: #2E293D;
  margin: 0 10vw;
}

.blog-header-container span {
  margin: calc(var(--oh) * 2) 24vw calc(var(--oh) * 5) 24vw;
  font-weight: 500;
  font-size: calc(var(--oh) * 1.65);
  line-height: 150%;
  letter-spacing: 0.8px;
  color: #A7A5B2;
}
</style>